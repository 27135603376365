import React from 'react'
import propTypes from 'prop-types'
import ConsentManagerWrapper from '~/components/Segment/ConsentManager'

import './static/fonts/fonts.css'

/**
 * Wraps the apps root element with Segment's Consent Manager.
 *
 * See:
 * https://github.com/segmentio/consent-manager
 * https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#wrapPageElement
 * https://github.com/segmentio/consent-manager/issues/10#issuecomment-679896358
 */
export const wrapPageElement = ({ element }) => {
  return <ConsentManagerWrapper>{element}</ConsentManagerWrapper>
}

wrapPageElement.propTypes = {
  element: propTypes.element.isRequired
}
