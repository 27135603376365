module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/buildhome/repo/src/intl"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"","environment":"production","enabled":"","denyUrls":[{},{}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"Z9ROLSxIN22qS2o9wS0KfZtKqsrT5yfM","devKey":"Z9ROLSxIN22qS2o9wS0KfZtKqsrT5yfM","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":true},
    },{
      plugin: require('../node_modules/@chordcommerce/gatsby-theme-performance/gatsby-browser.js'),
      options: {"plugins":[],"contentfulConfig":{"spaceId":"5i8ruvqe6zby","accessToken":"kg5r4LdtQsPgbl5QrumUdoC0Ho7vUlcz0MKSmnyL2Ew","host":"cdn.contentful.com","environment":"master"},"intlConfig":{"languages":["en-US"],"defaultLanguage":"en-US","path":"/opt/buildhome/repo/src/intl"},"siteMetadata":{"title":"Chord Commerce Starter","siteUrl":"https://www.chord.co","description":"A starter kit.","social":{"facebook":"chordcommerce","instagram":"chordcommerce","pinterest":"chordcommerce"}},"segmentConfig":{"prodKey":"Z9ROLSxIN22qS2o9wS0KfZtKqsrT5yfM","devKey":"Z9ROLSxIN22qS2o9wS0KfZtKqsrT5yfM","trackPage":true,"host":"https://cdn.segment.com","delayLoad":false,"delayLoadTime":1000,"manualLoad":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
